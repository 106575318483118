<template>
    <div class="fb-auth">
        <b-row v-if="tokens.length > 0 && validTokens.length > 0">
            <b-col md="8">
                <wit-select
                    v-model="token"
                    placeholder="Select Account"
                    :options="tokens"
                    id="token-multiselect"
                    @input="emitInput"
                    :disabled="disabled"
                    label="owner"
                    :filterBy="filterBy"
                >
                    <div slot="option" slot-scope="option">
                        <span>{{ `${option.owner.name}` }}</span>
                    </div>
                    <div slot="selected-option" slot-scope="option">
                        <span>{{ `${option.owner.name}` }}</span>
                    </div>
                </wit-select>
                <Feedback
                    :state="validateRef('token')"
                    invalid="This field is required"
                    valid="Token is valid"
                ></Feedback>
            </b-col>
            <b-col md="8">
                <span class="fb-auth-note fb-reauthorize">
                    or
                    <img
                        class="fb-auth-icon"
                        @click="authorize"
                        src="@/assets/gcp-icons/facebook-sign-in-button2.png"
                        v-bind:class="{'pointer-disabled': disabled}"
                    />
                    to use a different Account
                </span>
                <slot name="feedback" />
            </b-col>
        </b-row>
        <b-row v-if="tokens.length > 0 && validTokens.length === 0">
            <b-col md="8">
                <span class="fb-auth-note">
                    <img
                        class="fb-auth-icon"
                        @click="authorize"
                        src="@/assets/gcp-icons/facebook-sign-in-button2.png"
                        v-bind:class="{'pointer-disabled': disabled}"
                    />
                    <br />None of Your tokens meet the requirements. You have to authorize again to extend the scope.
                </span>
            </b-col>
        </b-row>
        <b-row v-if="tokens.length === 0">
            <b-col md="8">
                <span class="fb-auth-note">
                    <img
                        class="fb-auth-icon"
                        @click="authorize"
                        src="@/assets/gcp-icons/facebook-sign-in-button2.png"
                        v-bind:class="{'pointer-disabled': disabled}"
                    />
                    <br />You do not seem to have given us access to Facebook APIs. You have to authorize first to
                    select an authorized account.
                </span>
            </b-col>
        </b-row>
        <b-row v-if="tokenError">
            <b-col>
                <Feedback :state="tokenError.state" :invalid="tokenError.message"></Feedback>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback.vue'

export default {
    components: {Feedback},
    props: {
        value: {
            type: String | null,
            required: true,
        },
        scope: {
            type: String,
            required: true,
            default: 'ads_management',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [validationMixin],
    validations: {
        token: {required},
    },
    watch: {
        value() {
            this.token = this.tokens.find(el => el.id === this.value)
        },
    },
    async created() {
        await this.$store.dispatch('token/fetchTokens')
        if (this.value) {
            this.token = this.tokens.find(el => el.id === this.value)
        }
    },
    data() {
        return {
            token: null,
            tokenError: {
                state: null,
                message: null,
            },
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            tokens: 'token/facebookTokens',
        }),
        validTokens() {
            return this.tokens.filter(token => this.scope.includes('ads_management'))
        },
    },
    methods: {
        emitInput() {
            this.$emit('input', this.token.id)
        },
        authorize() {
            this.tokenError = {
                state: null,
                message: null,
            }

            const options = {
                scope: this.scope,
                projectId: this.activeProject.id,
            }

            this.$store.commit('loading/PROCESSING', `Authorizing...`)
            this.$store
                .dispatch(`facebook/upsertToken`, options)
                .then(async response => {
                    await this.$store.dispatch('token/fetchTokens')
                    this.$store.commit('loading/PROCESSED')
                })
                .catch(exception => {
                    this.tokenError = {
                        state: false,
                        message: 'Authorization error',
                    }
                    this.$store.commit('loading/PROCESSED')
                    this.$errorHandler.report(exception, 'Could not upsert Facebook token')
                })
        },
        validateRef(ref) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            return validateField.$dirty ? !validateField.$invalid : null
        },
        filterBy(option, label, search) {
            return option.owner.name.toLowerCase().indexOf(search.toLowerCase()) > -1
        },
    },
}
</script>

<style lang="scss">
.fb-reauthorize {
    opacity: 0.8;
}
.fb-auth {
    .fb-auth-note {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.7rem;
        img {
            width: 150px;
        }

        img.pointer-disabled {
            filter: grayscale(70%);
        }
    }
}
</style>
